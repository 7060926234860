$arrow: 6px;
$shadow: 0 4px 4px 0 rgba(100, 116, 139, 0.12);
$shadow-dark: 0 4px 4px 0 #00000029;
.__react_component_tooltip.show {
  box-shadow: $shadow;
  max-width: 400px;
  font-family: $btn-font-family;
  font-size: $font-size-sm;
  color: $body-text !important;
  text-transform: none;
  text-wrap: wrap;
  &.show {
    opacity: 1;
  }

  i {
    font-style: normal;
    color: $primary;
  }

  background-color: white;

  &:before {
    z-index: -1 !important;
  }
  &.place-top {
    &:after {
      box-shadow: $shadow;
      border-top-color: white;
      background-color: white;
      border-right-width: $arrow;
      border-left-width: $arrow;
      margin-top: -$arrow;
      padding-top: 1px;
    }
  }

  &.place-bottom {
    &:after {
      box-shadow: $shadow;
      border-bottom-color: white;
      background-color: white;
      border-right-width: $arrow;
      border-left-width: $arrow;
      margin-left: -$arrow;
      padding-bottom: 1px;
    }
  }

  &.place-right {
    &:after {
      box-shadow: $shadow;
      border-right-color: white;
      background-color: white;
      border-top-width: $arrow;
      border-bottom-width: $arrow;
      margin-top: -5px;
      padding-right: 1px;
    }
  }

  &.place-left {
    &:after {
      box-shadow: $shadow;
      border-left-color: white;
      background-color: white;
      border-top-width: $arrow;
      border-bottom-width: $arrow;
      margin-top: -5px;
      padding-left: 1px;
    }
  }
}

.dark {
  .__react_component_tooltip {
    background-color: $text-icon-dark;
    color: white !important;
    box-shadow: $shadow-dark;

    &.place-top {
      &:after {
        box-shadow: $shadow-dark;
        border-top-color: $text-icon-dark;
        background-color: $text-icon-dark;
      }
    }

    &.place-bottom {
      &:after {
        box-shadow: $shadow-dark;
        border-bottom-color: $text-icon-dark;
        background-color: $text-icon-dark;
      }
    }

    &.place-right {
      &:after {
        box-shadow: $shadow-dark;
        border-right-color: $text-icon-dark;
        background-color: $text-icon-dark;
      }
    }

    &.place-left {
      &:after {
        box-shadow: $shadow-dark;
        border-left-color: $text-icon-dark;
        background-color: $text-icon-dark;
      }
    }
  }
}

.recharts-tooltip {
  background-color: $text-icon-light;
  border-radius: $border-radius-lg;
}

.yAxis {
  font-size: $font-caption-sm;
  line-height: $line-height-xxsm;
  font-weight: 500;
}

.xAxis {
  font-size: $font-caption-xs;
  line-height: $line-height-xxsm;
  font-weight: 500;
}

.dark {
  .recharts-tooltip-header {
    color: $text-icon-dark;
  }

  .xAxis {
    color: $text-icon-light-grey;
  }

  .recharts-wrapper {
    line {
      stroke: $bg-dark100;
    }
  }
}
