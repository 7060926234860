@import "../variables";

.paging {
	padding: 5px;
	border-top: 1px solid $basic-alpha-16;
	.btn-paging {
		background: transparent;
		color: $body-text;
		width: 34px;
		height: 34px;
		padding: 0;
		line-height: 34px;
		border-radius: 17px !important;
		&:hover {
			color: $body-text;
			box-shadow: none;
			background: transparent !important;
		}
	}
	justify-content: center;
	.page {
		>div {
			height: 100%;
			justify-content: center;
			display: flex;
			align-items: center;
		}
		border-radius: $border-radius;
		margin-left: 4px;
		margin-right: 4px;
		min-width: 32px;
		height: 32px;
		line-height: 1;
		font-weight: 500;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 1px solid $hr-border-color;
		text-align: center;
		padding: 0;
		background: transparent;
		color: $text-icon-grey;
		&:hover {
			color: $primary;
		}
		&.active {
			background-color: $primary-alfa-8;
			color: $primary;
		}
	}
}

.dark{
	.paging{
		background-color: $bg-dark400;
		border-top: 1px solid $white-alpha-8;
	}
}
